<template>
  <!--<PageHeader :title="title" :items="items" />-->

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div>
          <img
            width="100%"
            v-if="loading"
            :src="'./images/loader.gif'"
            class="mr-2"
            style="margin-top: -5px;"
          />
        </div>
        <div class="col-12 mt-2" v-if="!loading">
          <div>
            <b-modal ref="my-modal" hide-footer title="Confirmation">
              <div class="d-block">
                <h4>{{ this.modalBodyText }}</h4>
              </div>
              <div style="float: right;">
                <button
                  type="button"
                  ref="kt_login_signin_submit"
                  class="btn btn-danger mr-3"
                  @click="changeStatus()"
                >
                  Yes
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="hideModal"
                >
                  No
                </button>
              </div>
            </b-modal>
          </div>

          <div>
            <b-modal ref="price_brack_down" hide-footer title="Price Breakdown">
              <div>
                <span>
                  Selected garbage % = <span>{{ this.garbage }}</span></span
                >
                <span style="float:right;">
                  Total Amount
                  <span>{{ this.estimatedPrice + "£" }}</span></span
                >
              </div>
              <div style="margin-top: 8px;">
                <span>
                  SkipShare Portion =
                  <span>{{
                    this.skipsharePercentage.toFixed(2) + "£"
                  }}</span></span
                >
                <span style="float:right;">
                  Amount that will earn =
                  <span>{{
                    this.AmountBeforeSkipShare.toFixed(2) + "£"
                  }}</span></span
                >
              </div>
              <div style="float: right;margin-top: 8px;">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="hideModal"
                >
                  ok
                </button>
              </div>
            </b-modal>
          </div>
          <template v-if="isShowModel">
            <VerficationModal
              :show="showModal"
              :userObj="userObj"
              :actionType="actionType"
              @changeStatus="singlechangeStatus"
              @deleteRow="deleteRow"
              @closeModal="closeModal"
            />
          </template>

          <div
            v-if="alert"
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            class="alert alert-success"
          >
            {{ alertText }}
          </div>
          <router-link to="/skip-request-add">
            <button class="btn btn-success btn btn-success rounded-pill mb-2">
              Add
            </button>
          </router-link>
          <button
            style="float: right; margin-left: 12px"
            class="btn btn-danger btn btn-danger rounded-pill mb-2"
            data-toggle="modal"
            data-target="#modalId"
            @click="confirmationBulkAction('delete')"
          >
            Delete
          </button>

          <button
            style="float: right;margin-left: 12px"
            class="btn btn-info btn btn-info rounded-pill mb-2"
            @click="confirmationBulkAction('deactive')"
          >
            Deactive
          </button>

          <button
            style="float: right;margin-left: 12px"
            class="btn btn-primary btn btn-primary rounded-pill mb-2"
            id="add-loan-btn"
            @click="confirmationBulkAction('active')"
          >
            Active
          </button>
          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">Sharing Requests</h4>
          </div>
        </div>

        <div class="card-body" v-if="!loading">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                  entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>

          <div class="table-responsive mb-0">
            <b-table
              :items="loans"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              ref="selectableTable"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              show-empty
              empty-text="No record found"
              empty-filtered-text="No search result found"
            >
              <template #head(id)>
                <input v-model="isSelectAll" type="checkbox" />
              </template>

              <template v-slot:cell(skip_to_share_id)="loans">
                <span
                  v-if="loans.item.skip_to_share_id != null"
                  @click="skipDetail(loans)"
                  style="cursor: pointer;border-bottom: 2px solid red;width: 70px!important"
                  title="Click Here For Show Request List"
                  >{{ loans.item.skip_to_share_id }}
                </span>
              </template>
              <template v-slot:cell(id)="loans">
                <input
                  type="checkbox"
                  :value="loans.item.id"
                  v-model="checkedNames"
                />
              </template>

              <template v-slot:cell(status)="loans">
                <i
                  v-if="loans.item.status == '1'"
                  style="color:green;cursor: pointer;"
                  class="fas fa-check"
                  @click="singlActiveDeactive(loans)"
                ></i
                >&nbsp;
                <i
                  v-if="loans.item.status == '0'"
                  style="color:red;cursor: pointer;"
                  class="fas fa-times"
                  @click="singlActiveDeactive(loans)"
                ></i>
              </template>

              <template v-slot:cell(actions)="loans">
                <i
                  class="fas fa-edit"
                  style="color:#4e4e63;cursor: pointer;"
                  @click="editSkipRequest(loans)"
                ></i
                >&nbsp;
                <i
                  style="color:red;cursor: pointer;"
                  class="fas fa-trash-alt"
                  @click="deleteUser(loans)"
                ></i>
              </template>

              <template v-slot:cell(price_brack_down)="loans">
                <i
                  style="color:black;cursor: pointer;margin-top: 6px;"
                  class="fas fa-eye"
                  @click="priceBreakdown(loans)"
                ></i>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";

import VerficationModal from "./VerficationModal";
import axios from "axios";
export default {
  page: {
    title: "Collateral",
  },

  components: {
    VerficationModal,
  },
  data() {
    return {
      loans: [],
      alert: false,
      loading: true,
      alertText: "",
      isModel: false,
      modalBodyText: "",
      actionType: "",
      modalTitle: "",
      buttonStatus: "",
      title: "Users",
      showModal: false,
      checkedNames: [],
      customerId: "",
      userObj: {},
      isShowModel: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      showDeleteModal: false,
      sortDesc: true,
      isSelectAll: false,
      garbage: 0,
      estimatedPrice: 0,
      skipsharePercentage: 0,
      AmountBeforeSkipShare: 0,
      fields: [
        { key: "id", label: "Select" },
        {
          key: "requestUserDetail.username",
          label: "UserName",
          sortable: true,
        },
        { key: "requestUserDetail.zipcode", label: "Zip Code", sortable: true },
        { key: "skip_to_share_id", label: "Skip ID", sortable: true },
        { key: "garbage_space", label: "Garbage (%)", sortable: true },
        // { key: "expiry_date", label: "Expiry Date", sortable: true },
        { key: "request_accepted", label: "Request Accepted", sortable: true },
        { key: "payment_status", label: "Payment Status", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "actions", sortable: true },
        { key: "price_brack_down", label: "Price Breakdown", sortable: true },
      ],
    };
  },
  computed: {
    rows() {
      return this.loans.length;
    },
  },
  watch: {
    isSelectAll: function(val) {
      if (val) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (x) => x.id
        );
      } else {
        this.checkedNames = [];
      }
    },
  },
  methods: {
    confirmationBulkAction(status) {
      if (this.checkedNames.length > 0) {
        if (status == "delete") {
          this.status = "delete";
          this.modalBodyText = "Do You Want To Delete This Record?";
        }
        if (status == "active") {
          this.status = "active";
          this.modalBodyText = "Do You Want To Active This Record?";
        }
        if (status == "deactive") {
          this.status = "deactive";
          this.modalBodyText = "Do You Want To Deactive This Record?";
        }
        this.$refs["my-modal"].show();
      } else {
        this.alert = true;
        this.alertText = "Please Select checkbox";
        this.hideAlert();
      }
    },
    closeModal(value) {
      this.isShowModel = value;
    },

    skipDetail(object) {
      localStorage.setItem(
        "skipId",
        JSON.stringify(parseInt(object.item.skip_to_share_id))
      );
      this.$router.push({ name: "skipdetail" });
    },

    editSkipRequest(object) {
      localStorage.setItem("skipRequestEdit", JSON.stringify(object.item));
      this.$router.push({ name: "skip-request-edit" });
    },

    deleteRow(getUser) {
      let index = this.loans.findIndex((x) => x.id === getUser.item.id);
      if (index !== -1) {
        this.loans.splice(index, 1);
      }

      this.alert = true;
      this.alertText = "Record Updated Successfully";
      this.hideAlert();
    },

    singlechangeStatus(getUser) {
      let index = this.loans.findIndex((x) => x.id === getUser.id);
      if (index !== -1) {
        this.loans[index].status = getUser.status;
      }
      this.alert = true;
      this.alertText = "Record Updated Successfully";
      this.hideAlert();
    },

    hideModal() {
      this.$refs["my-modal"].hide();
      this.$refs["price_brack_down"].hide();
    },
    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },

    toasterMessageFalse(message) {
      Vue.$toast.error(message, {
        position: "top-right",
        duration: 5000,
      });
    },

    deleteUser(object) {
      if (object.item.payment_status == "Yes") {
        this.toasterMessageFalse(
          "You can not delete this request because payment is done against this request"
        );
        return false;
      }
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = "delete";
    },
    singlActiveDeactive(object) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      if (this.userObj.item.status == "1") {
        this.actionType = "active";
      }
      if (this.userObj.item.status == "0") {
        this.actionType = "deactive";
      }
    },
    addClassToButton() {
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    priceBreakdown(object) {
      const originalPrice =
        object.item.estimated_price /
        (1 + object.item.skipdetail.skip_share_percentage / 100);
      this.garbage = object.item.garbage_space;
      this.estimatedPrice = object.item.estimated_price;
      this.skipsharePercentage =
        (originalPrice * object.item.skipdetail.skip_share_percentage) / 100;
      // const originalPrice = object.item.estimated_price;

      this.AmountBeforeSkipShare =
        object.item.estimated_price - this.skipsharePercentage;
      this.$refs["price_brack_down"].show();
    },
    changeStatus() {
      try {
        this.addClassToButton();
        this.showDeleteModal = true;
        const data = { status: this.status, bluckid: this.checkedNames };
        // const headers = {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // };
        ApiService.post(this.$path + "/skip-request-bulk-action", data, {
          // headers,
        }).then((response) => {
          if (response.data.code == 200) {
            this.$refs["my-modal"].hide();
            this.getAllCustomer();
            this.alert = true;
            this.alertText = "Record Updated Successfully";
            this.checkedNames = [];
            this.hideAlert();
            this.isSelectAll = false;
          }
          if (response.data.code == 422) {
            this.toasterMessageFalse(response.data.message);
            this.$refs["my-modal"].hide();
            this.checkedNames = [];
            this.isSelectAll = false;
            return false;
          }
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getAllCustomer() {
      try {
        axios.get(this.$path + "/skip-request-list").then((response) => {
          this.loans = response.data.data;
          this.loading = false;
          if ("alertmessage" in localStorage) {
            this.alert = true;
            this.alertText = JSON.parse(localStorage.getItem("alertmessage"));
            this.hideAlert();
            localStorage.removeItem("alertmessage");
          }
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },
    changeVerfiedtext(loans) {
      if (loans.item.status == 1) {
        return "Active";
      } else {
        return "Deactive";
      }
    },
    changeClass(loans) {
      if (loans.item.status == 1) {
        return "btn btn-info btn-sm";
      } else {
        return "btn btn-primary btn-sm";
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addLoan() {
      this.$router.push({ name: "add-loan", params: { is_edit: false } });
    },

    editLoan(loan) {
      this.$router.push({
        name: "add-loan",
        params: { is_edit: true, loan_id: loan.item.id },
      });
    },
    // getAllCustomer(){
    //   ApisLists.getCustomers()
    //   .then(loans => {
    //    this.loans=loans;
    //   }).catch(error=>console.log(error))
    //     .finally(()=>{
    //     })
    // },
  },
  mounted() {
    document.title = "Sharing Requests";
    this.totalRows = this.loans.length;
    this.getAllCustomer();
    // this.getAllCustomer()
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
</style>
